var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c("h1", [_vm._v("登録が完了しました")]),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("div", [
            _vm._v(
              "これより、ヤマラボの有料プラン限定機能をご利用いただけます。"
            )
          ]),
          _c(
            "router-link",
            { staticClass: "btn btn-primary my-3", attrs: { to: "/" } },
            [_vm._v("トップページに戻る")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-5 text-center" }, [
      _c("img", { attrs: { src: "/img/register/status_04.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }